import { Helmet } from "react-helmet";
import Layout from "./components/Layout/Layout";
import { useLocations } from "./providers/LocationsProvider";

function App() {
  const { currentLocation } = useLocations();
  return !currentLocation ? null : (
    <>
      <Helmet>
        <title>{`Päivän pituus ${currentLocation.name_inflected}`}</title>
      </Helmet>
      <Layout />
    </>
  );
}

export default App;
