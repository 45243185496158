import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultLocation } from "../config";
import kunnat from "../kunnat.json";

import {
  getNearest,
  getGeoPosition,
  calculateDaylightInfo,
  calculateYearlySunTimes,
  calculateDayLightChanges,
  calculateSpecialEvents,
  parseUrl,
} from "../utils";
import { format, getDate, getMonth } from "date-fns";

const LocationsContext = React.createContext();

export function useLocations() {
  return useContext(LocationsContext);
}

export function LocationsProvider(props) {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [positioningStatus, setPositioningStatus] = useState("idle");
  const [customDate, setCustomDate] = useState(null);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const onVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        console.log("ok computer");
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);

  useEffect(() => {
    let location = defaultLocation;
    let date = new Date();
    let path = parseUrl(pathname);

    if (pathname.indexOf("&date") !== -1) {
      const dateIdx = pathname.indexOf("&date");
      const dateString = pathname.slice(dateIdx + 6);
      const urlDate = new Date(dateString);
      if (urlDate.toString() !== "Invalid Date") {
        date = urlDate;
      }
      path = pathname.slice(0, dateIdx);
      setCustomDate(date);
    } else {
      setCustomDate(null);
    }

    const urlLocationString = parseUrl(path.slice(1));

    if (urlLocationString) {
      const urlLocation = kunnat.find(
        (item) => item.name.toLowerCase() === urlLocationString.toLowerCase()
      );

      if (urlLocation) {
        location = urlLocation;
      } else {
        alert('Kohdetta "' + urlLocationString + '" ei löydy.');
      }
    }

    const loc = initializeLocation(location, date);

    setCurrentLocation(loc);
    setCurrentDate(date);
    // document.title = `PäivänPituus.fi - ${location.name}`;
  }, [pathname]);

  function initializeLocation(location, date) {
    const daylightInfo = calculateDaylightInfo(location, date);

    const yearlySunTimes = calculateYearlySunTimes(location, date);
    const polarNight = calculateSpecialEvents(location, date, "polarNight");
    const midnightSun = calculateSpecialEvents(location, date, "midnightSun");

    const dayLengthChanges = calculateDayLightChanges(
      location,
      daylightInfo,
      date,
      polarNight,
      midnightSun
    );

    const timeZone = location.timeZone ? location.timeZone : "Europe/Helsinki";

    return {
      ...location,
      timeZone,
      daylightInfo,
      dayLengthChanges,
      polarNight,
      midnightSun,
      yearlySunTimes,
    };
  }

  async function findGeoLocation() {
    setPositioningStatus("working");

    try {
      const geoPosition = await getGeoPosition();

      let nearest = getNearest(
        geoPosition.coords.latitude,
        geoPosition.coords.longitude
      );

      handleSetLocation(nearest, "location");
      setPositioningStatus("ready");
    } catch (error) {
      setPositioningStatus("ready");

      const errorMessage =
        error.code === 1
          ? "Paikannustoiminto estetty selaimen asetuksissa."
          : "Paikantaminen epäonnistui. Valitse lähin paikkakunta valikosta.";

      alert(errorMessage);
    }
  }

  function changeDate(month, day) {
    const today = new Date();

    if (month === format(today, "M") && day === format(today, "d")) {
      navigate(`/${currentLocation.name}`);
      return;
    }
    const date = new Date(`${new Date().getFullYear()}-${month}-${day}`);

    const dateString = format(date, "yyyy-MM-dd");
    let hours = new Date().getHours();
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = new Date().getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    navigate(
      `/${currentLocation.name}&date=${dateString}T${hours}:${minutes}:00`
    );
  }

  function handleSetLocation(location) {
    navigate(`/${location.name}`);
  }

  return (
    <LocationsContext.Provider
      value={{
        findGeoLocation,
        positioningStatus,
        currentLocation,
        handleSetLocation,
        currentDate,
        changeDate,
        customDate,
      }}
    >
      {props.children}
    </LocationsContext.Provider>
  );
}
