import React, { useMemo } from "react";

import { useLocations } from "../../providers/LocationsProvider";

import Moon from "./Moon";
import SunDialContent from "./SunDialContent";
import SunDialGradient from "./SunDialGradient";

import dialbg from "../../assets/dial_frame.webp";
import pointer_day from "../../assets/dial_pointer_day.png";
import pointer_night from "../../assets/dial_pointer_night.png";
import { startOfDay, subDays } from "date-fns";
import { useTheme } from "../../providers/ThemeProvider";
import { calculateSunrise, calculateSunset } from "../../utils";
import { utcToZonedTime } from "date-fns-tz";

export default function SunDialContainer({ showingSunDialChange }) {
  const { currentDate, currentLocation } = useLocations();

  const { theme } = useTheme();

  const localDate = utcToZonedTime(currentDate, currentLocation.timeZone);

  const currentMinutes = localDate.getHours() * 60 + localDate.getMinutes();
  const currentTime = `${localDate.getHours()}:${localDate.getMinutes()}`;
  const currentTimePointerRotation = (currentMinutes / 1440).toFixed(2);

  let daylightArch = useMemo(() => {
    let { sunrise, sunset } = currentLocation.daylightInfo;

    sunrise = utcToZonedTime(sunrise.time, currentLocation.timeZone);
    sunset = utcToZonedTime(sunset.time, currentLocation.timeZone);

    const sunriseMinutes = sunrise.getHours() * 60 + sunrise.getMinutes();
    let sunrisePercentage = (sunriseMinutes / 1440) * 100;

    let sunsetMinutes = sunset.getHours() * 60 + sunset.getMinutes();

    if (sunset.getMonth() > 3 && sunset.getMonth() < 8 && sunsetMinutes < 100) {
      sunsetMinutes = sunsetMinutes + 1440;
    }

    let sunsetPercentage = (sunsetMinutes / 1440) * 100;

    let archLength = sunsetPercentage - sunrisePercentage;

    if (
      startOfDay(currentDate) >= currentLocation.midnightSun.start &&
      startOfDay(currentDate) <= currentLocation.midnightSun.end
    ) {
      archLength = 100;
    }

    if (
      startOfDay(currentDate) >= currentLocation.polarNight.start &&
      startOfDay(currentDate) <= currentLocation.polarNight.end
    ) {
      archLength = 0;
    }

    const rotation =
      1 + Math.abs(50 - archLength / 2 - sunrisePercentage) / 100;

    let changeArchLength = -1;

    if (showingSunDialChange) {
      const date = subDays(currentDate, showingSunDialChange);
      sunrise = calculateSunrise(
        currentLocation.latitude,
        currentLocation.longitude,
        date
      );
      sunset = calculateSunset(
        currentLocation.latitude,
        currentLocation.longitude,
        date
      );

      sunrise = utcToZonedTime(sunrise.time, currentLocation.timeZone);
      sunset = utcToZonedTime(sunset.time, currentLocation.timeZone);

      const sunriseMinutes = sunrise.getHours() * 60 + sunrise.getMinutes();
      let sunrisePercentage = (sunriseMinutes / 1440) * 100;

      let sunsetMinutes = sunset.getHours() * 60 + sunset.getMinutes();

      if (
        sunset.getMonth() > 3 &&
        sunset.getMonth() < 8 &&
        sunsetMinutes < 100
      ) {
        sunsetMinutes = sunsetMinutes + 1440;
      }

      let sunsetPercentage = (sunsetMinutes / 1440) * 100;

      changeArchLength = sunsetPercentage - sunrisePercentage;

      if (
        startOfDay(date) >= currentLocation.midnightSun.start &&
        startOfDay(date) <= currentLocation.midnightSun.end
      ) {
        changeArchLength = 100;
      }

      if (
        startOfDay(date) >= currentLocation.polarNight.start &&
        startOfDay(date) <= currentLocation.polarNight.end
      ) {
        changeArchLength = 0;
      }
    }

    return {
      start: sunrisePercentage,
      end: sunsetPercentage,
      archLength: Math.round(archLength),
      rotation,
      changeArchLength: Math.round(changeArchLength),
    };
  }, [currentLocation, currentDate, showingSunDialChange]);

  const mask = `radial-gradient(
    closest-side,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 88%,
    rgba(0, 0, 0, 1) 88.5%,
    rgba(0, 0, 0, 1) 99.5%,
    rgba(0, 0, 0, 0) 100%
  )`;

  return (
    <div className="relative mx-auto mb-6 sm:mb-10 lg:-ml-20px">
      <div
        className="
        relative 
        w-[96vw] h-[96vw] max-w-[550px] max-h-[550px] md:max-w-[580px] md:max-h-[580px] min-w-[300px] min-h-[300px]
        lg:mr-auto
        overflow-hidden lg:overflow-visible 
      "
      >
        <SunDialContent sunDialChange={showingSunDialChange} />
        <div
          style={{
            position: "absolute",
            top: "9%",
            left: "9%",
            width: "82%",
            height: "82%",
            WebkitMaskImage: mask,
            maskImage: mask,
          }}
          className="
            border-[3px] xs:border-[5px] border-day-light dark:border-night-light 
            rounded-full 
            pointer-events-none
          "
        >
          <SunDialGradient daylightArch={daylightArch} />
        </div>
        <div
          className="
            absolute 
            top-0 left-0 
            w-full h-full 
            select-none pointer-events-none
          "
          style={{
            transform: `rotate(${currentTimePointerRotation - 0.5}turn)`,
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={theme === "dark" ? pointer_night : pointer_day}
            alt={`Kello on ${currentTime}`}
            data-nosnippet
          />
        </div>
        <div
          style={{ opacity: theme === "dark" ? "0.7" : "1" }}
          className="
            absolute 
            top-0 left-0 
            w-full h-full 
            select-none pointer-events-none
          "
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={dialbg}
            alt=""
            data-nosnippet
          />
        </div>
      </div>

      <Moon />
    </div>
  );
}
