import React, { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { format } from "date-fns";

import { useLocations } from "../../providers/LocationsProvider";
import { useModal } from "../../providers/ModalProvider";

import { specialEvents } from "../../config";

import Header from "./Header";
import LocationInfo from "./LocationInfo";
import SunDialContainer from "../SunDial/SunDialContainer";
import Changes from "../Changes/Changes";
import ChartContainer from "../Chart/ChartContainer";
import CityListContainer from "../Cities/CityListContainer";
import Footer from "./Footer";
import Modal from "../Modals/Modal";
import Divider from "./Divider";
import SpecialEventNote from "./SpecialEventNote";

function SectionWrapper({ children }) {
  return (
    <div
      className="
      w-full 
      py-8 sm:py-12 
      bg-gradient-to-b from-day-dark to-day-light dark:from-night-dark dark:to-night-light
    "
    >
      {children}
    </div>
  );
}
function WidthWrapper({ children }) {
  return <div className="w-11/12 max-w-[1200px] mx-auto">{children}</div>;
}

export default function Layout() {
  const { modalContent, closeModal } = useModal();
  const [specialEvent, setSpecialEvent] = useState("");
  const { currentDate } = useLocations();
  const [showingSunDialChange, setShowingSunDialChange] = useState("");
  const { currentLocation } = useLocations();

  useEffect(() => {
    const today = format(currentDate, "yyyyMMdd");
    if (specialEvents[today]) {
      setSpecialEvent(specialEvents[today]);
    } else {
      setSpecialEvent("");
    }
  }, [currentDate]);

  function toggleSunDialChange(change) {
    if (change === showingSunDialChange) {
      setShowingSunDialChange("");
      return;
    }
    setShowingSunDialChange(change);
  }

  return (
    <div
      className="
      flex flex-col items-center 
      w-full
      bg-day-light bg-daybg dark:bg-night-light dark:bg-nightbg 
      bg-[length:auto_90vh] sm:bg-[length:auto_1000px] bg-repeat-x
      "
      style={{ backgroundPosition: "130% 0" }}
    >
      <AnimatePresence>
        {modalContent && (
          <Modal close={closeModal} key="modal">
            {modalContent}
          </Modal>
        )}
      </AnimatePresence>

      {specialEvent && <SpecialEventNote event={specialEvent} />}
      <Header />
      <LocationInfo />
      <div
        className="
        flex flex-col items-center lg:flex-row lg:gap-12 
        w-full lg:w-11/12 max-w-[1200px]
        mt-4 sm:mt-8 lg:mt-12 pb-10 
      "
      >
        <SunDialContainer showingSunDialChange={showingSunDialChange} />
        <div className="w-11/12 lg:w-full">
          <Changes
            sunDialChange={showingSunDialChange}
            showSunDialChange={(change) => toggleSunDialChange(change)}
          />
        </div>
      </div>
      <Divider />
      <SectionWrapper>
        <WidthWrapper>
          <ChartContainer />
        </WidthWrapper>
      </SectionWrapper>
      <Divider />
      <SectionWrapper>
        <WidthWrapper>
          <CityListContainer region="fin" />
        </WidthWrapper>
      </SectionWrapper>
      <Divider />
      <SectionWrapper>
        <WidthWrapper>
          <CityListContainer region="world" />
        </WidthWrapper>
      </SectionWrapper>
      <Divider />
      <Footer />
    </div>
  );
}
